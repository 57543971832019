<template>
  <div class="tech-service-basic-edit">
    <el-form class="zwx-form edit-form" key="mainForm" ref="mainForm" :model="mainForm" :rules="rules" @submit.native.prevent>
      <el-form-item label-width="110px" label="单位名称：" prop="organName">
        <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.organName" placeholder="请输入" clearable />
        <!-- <unit-select width="240px" v-model="mainForm.organName" placeholderText="请输入5个字符搜索" :requestUrl="requestUrl" :ifAuthority="false" @unitNameDel="unitNameDel" @change="unitNameSelect" @loading="data => $emit('loading', data)" /> -->
      </el-form-item>

      <el-form-item label-width="110px" label="社会信用代码：" prop="creditCode">
        <!-- <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.creditCode" placeholder="请输入" clearable /> -->
        <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.creditCode" placeholder="请输入社会信用代码" clearable disabled />
      </el-form-item>

      <div>
        <el-form-item label-width="110px" label="注册地区：" prop="zoneCode">
          <zwx-select-area ref="zoneCodeArea" class="select-component" placeholder="请选择" @change="nativePlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" />
        </el-form-item>

        <el-form-item label-width="110px" label="注册地址：" prop="workAddr">
          <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.workAddr" placeholder="请输入" maxlength="400" clearable />
        </el-form-item>
      </div>

      <div>
        <el-form-item label-width="110px" label="法人：" prop="legalPerson">
          <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.legalPerson" placeholder="请输入" clearable />
        </el-form-item>

        <el-form-item label-width="110px" label="联系电话：" prop="telephone">
          <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.telephone" placeholder="请输入" maxlength="400" clearable />
        </el-form-item>
      </div>

      <div>
        <el-form-item label-width="110px" label="联系人：" prop="linkMan">
          <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.linkMan" placeholder="请输入" clearable />
        </el-form-item>

        <el-form-item label-width="110px" label="联系人电话：" prop="linkMobile">
          <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.linkMobile" placeholder="请输入" maxlength="400" clearable />
        </el-form-item>
      </div>

      <div>
        <el-form-item label-width="110px" label="电子邮箱：" prop="email">
          <el-input class="zwx-input" style="width:240px !important" v-model="mainForm.email" placeholder="请输入" clearable />
        </el-form-item>

        <el-form-item label-width="110px" label="是否启用：" prop="ifEnable">
          <el-radio-group class="zwx-radio-group" v-model="mainForm.ifEnable">
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div>
        <el-form-item label-width="110px" label="公司简介：" prop="introducePath">
          <file-upload ref="coverPictureRef1" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" @showFileList="picFileShowFileList1" @success="picFileUploadSuccess1" :size="5 * 1024 * 1024" sizeInfo="5M">
            <el-button class="zwx-button zwx-button-icontext-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(mainForm.annexList1)">添加附件</el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(mainForm.annexList1)">
            <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(mainForm.annexList1, 0)">{{ mainForm.introduceName }}</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile1(0)">删除</el-button>
          </div>
        </el-form-item>
      </div>

      <div>
        <el-form-item label-width="110px" label="营业执照：" prop="licensePath">
          <file-upload ref="coverPictureRef2" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" @showFileList="picFileShowFileList2" @success="picFileUploadSuccess2" :size="5 * 1024 * 1024" sizeInfo="5M">
            <el-button class="zwx-button zwx-button-icontext-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(mainForm.annexList2)">添加附件</el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(mainForm.annexList2)">
            <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(mainForm.annexList2, 0)">{{ mainForm.licenseName }}</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile2(0)">删除</el-button>
          </div>
        </el-form-item>
      </div>

      <div>
        <el-form-item label-width="110px" label="员工证明：" prop="employerPath">
          <file-upload ref="coverPictureRef3" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" @showFileList="picFileShowFileList3" @success="picFileUploadSuccess3" :size="5 * 1024 * 1024" sizeInfo="5M">
            <el-button class="zwx-button zwx-button-icontext-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(mainForm.annexList3)">添加附件</el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(mainForm.annexList3)">
            <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(mainForm.annexList3, 0)">{{ mainForm.employerName }}</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile3(0)">删除</el-button>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="180px" label="本单位报告公示网址：" prop="publicityUrl">
          <el-input class="zwx-input" style="width:540px !important" v-model="mainForm.publicityUrl" placeholder="请输入" clearable />
        </el-form-item>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="save">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'TechServiceBasicEdit',
  components: {},
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      rid: this.$route.params.rid,
      folder: 'repository/technical',
      zoneCode12From: '000000000000',
      mainForm: {
        rid: '',
        organName: '',
        creditCode: '',
        zoneCode: '',
        workAddr: '',
        legalPerson: '',
        linkMan: '',
        linkMobile: '',
        telephone: '',
        email: '',
        ifEnable: true,
        introduceName: '',
        introducePath: '',
        licenseName: '',
        licensePath: '',
        employerName: '',
        employerPath: '',
        zoneType: '',
        annexList1: [],
        annexList2: [],
        annexList3: [],
        publicityUrl: '',
      },
      requestUrl: '/data/exchange/getUnitListFromEnterprise-0',
      creditCodeDisabled: false,
      rules: {
        organName: [
          { required: true, message: '请输入单位名称', trigger: ['change', 'blur'] },
          { validator: this.validateUnitName, trigger: ['blur'] },
        ],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: ['change', , 'blur'] },
          { validator: this.$validate.creditCode, trigger: ['blur'] },
        ],
        zoneCode: [
          { required: true, message: '请选择注册地区', trigger: ['change', , 'blur'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        workAddr: [{ required: true, message: '请输入注册地址', trigger: ['blur', 'change'] }],
        legalPerson: [{ required: true, message: '请输入法人', trigger: ['change', 'blur'] }],
        linkMan: [{ required: true, message: '请输入联系人', trigger: ['change', 'blur'] }],
        linkMobile: [
          { required: true, message: '请输入联系人电话', trigger: ['change', , 'blur'] },
          { validator: this.$validate.mobilePhone, trigger: ['blur', 'change'] },
        ],
        telephone: [
          { required: true, message: '请输入联系电话', trigger: ['change', , 'blur'] },
          { validator: this.$validate.mbPeLdline, trigger: ['blur', 'change'] },
        ],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: ['change', , 'blur'] },
          { validator: this.$validate.email, trigger: ['blur', 'change'] },
        ],
        // publicityUrl: [
        //   { required: true, message: '请输入报告公示网址', trigger: ['change', , 'blur'] },
        //   { validator: this.urlValidator, trigger: ['blur'] },
        // ],
        ifEnable: [{ required: true, message: '请选择是否启用', trigger: ['change', 'blur'] }],
        introducePath: [{ required: true, message: '请上传公司简介', trigger: ['change', 'blur'] }],
        licensePath: [{ required: true, message: '请上传营业执照', trigger: ['change', 'blur'] }],
        employerPath: [{ required: true, message: '请上传员工证明', trigger: ['change', 'blur'] }],
      },
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  watch: {},
  created() {
    if (this.rid) {
      this.$emit('breadcrumb', '编辑', true)
    }
  },
  mounted() {
    this.$refs.zoneCodeArea.initSearch()
    if (this.rid) {
      this.detail()
    }
  },
  methods: {
    /**
     * 单位名称长度校验
     */
    validateUnitName(rule, value, callback) {
      if (value != undefined && value != null && value != '' && value.length < 5) {
        callback(new Error('至少输入5个字符进行检索'))
      }
      callback()
    },
    //机构清空回调方法
    unitNameDel() {
      this.zoneCode12From = '000000000000'
      this.creditCodeDisabled = false
      this.searchUnitList = []
      this.mainForm.creditCode = ''
      this.mainForm.organName = ''
      this.unitNameSearchVisible = false
      this.$nextTick(() => {
        this.$refs['mainForm'].clearValidate()
      })
    },
    // 单位信息选择回调方法
    unitNameSelect(item) {
      //客户信息
      this.mainForm.organName = item.unitName
      this.mainForm.creditCode = item.creditCode
      if (this.$zwxBase.verifyIsNotBlank(this.mainForm.creditCode)) {
        this.creditCodeDisabled = true
      } else {
        this.creditCodeDisabled = false
      }
    },
    detail() {
      let data = {
        rid: this.rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/repository/getTechServiceBasic-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.mainForm = data.techServiceBasic
            this.mainForm = {
              rid: data.techServiceBasic.rid,
              organName: data.techServiceBasic.organName,
              creditCode: data.techServiceBasic.creditCode,
              zoneCode: data.techServiceBasic.zoneCode,
              workAddr: data.techServiceBasic.workAddr,
              legalPerson: data.techServiceBasic.legalPerson,
              linkMan: data.techServiceBasic.linkMan,
              linkMobile: data.techServiceBasic.linkMobile,
              telephone: data.techServiceBasic.telephone,
              email: data.techServiceBasic.email,
              ifEnable: data.techServiceBasic.ifEnable,
              introduceName: '',
              introducePath: '',
              licenseName: '',
              licensePath: '',
              employerName: '',
              employerPath: '',
              zoneType: data.techServiceBasic.zoneType,
              annexList1: [],
              annexList2: [],
              annexList3: [],
              publicityUrl: data.techServiceBasic.publicityUrl || '',
            }
            this.$refs.zoneCodeArea.initSearch(this.mainForm.zoneCode)
            this.mainForm.linkMobile = this.$zwxSm.sm4JsDecrypt(data.techServiceBasic.linkMobile)
            this.mainForm.annexList1 = [
              {
                fileName: data.techServiceBasic.introduceName,
                filePath: data.techServiceBasic.introducePath,
              },
            ]
            this.mainForm.introduceName = data.techServiceBasic.introduceName
            this.mainForm.introducePath = data.techServiceBasic.introducePath

            this.mainForm.annexList2 = [
              {
                fileName: data.techServiceBasic.licenseName,
                filePath: data.techServiceBasic.licensePath,
              },
            ]
            this.mainForm.licenseName = data.techServiceBasic.licenseName
            this.mainForm.licensePath = data.techServiceBasic.licensePath

            this.mainForm.annexList3 = [
              {
                fileName: data.techServiceBasic.employerName,
                filePath: data.techServiceBasic.employerPath,
              },
            ]
            this.mainForm.employerName = data.techServiceBasic.employerName
            this.mainForm.employerPath = data.techServiceBasic.employerPath
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    save() {
      this.$refs.mainForm.validate(valid => {
        if (valid) {
          this.saveData()
        }
      })
    },
    /**
     * 保存数据
     */
    saveData() {
      let data = { ...this.mainForm }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/repository/addOrModifyTechServiceBasic-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({ name: 'QualificationManage', params: { fresh: true } })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    /**
     * 地区校验
     */
    validateBizZone(rule, value, callback) {
      console.log(this.mainForm.zoneType, value)
      if (this.mainForm.zoneType < 3) {
        callback(new Error('请精确到区级'))
      }
      callback()
    },
    /**
     * 网址校验
     */
    urlValidator(rule, value, callback) {
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/
      if (!reg.test(value)) {
        callback(new Error('请输入正确网址'))
      }
      callback()
    },
    //经营地区
    nativePlaceChange() {
      this.mainForm.zoneType = this.$refs.zoneCodeArea.getChooseArea() == null ? null : this.$refs.zoneCodeArea.getChooseArea().zoneType
      this.mainForm.zoneCode = this.$refs.zoneCodeArea.getChooseArea() == null ? null : this.$refs.zoneCodeArea.getChooseArea().zoneCode12
    },
    /**
     * 公司简介文件上传显示回调
     */
    picFileShowFileList1(showList) {
      showList.forEach(item => {
        let flag = this.mainForm.annexList1.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.mainForm.annexList1.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid1()
      }
    },
    // 获取公司简介文件的UID
    generateFilesUid1() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef1.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 公司简介文件上传成功回调
     */
    picFileUploadSuccess1(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList1) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.mainForm.annexList1) {
          for (let j in fileList) {
            if (this.mainForm.annexList1[i].fileName === fileList[j].fileName) {
              this.mainForm.annexList1[i].filePath = fileList[j].filePath
              this.mainForm.introduceName = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList1) ? this.mainForm.annexList1[0].fileName : null
              this.mainForm.introducePath = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList1) ? this.mainForm.annexList1[0].filePath : null
            }
          }
        }
      }
    },
    /**
     * 删除公司简介文件
     */
    deletionPicFile1(index) {
      let fileName = this.mainForm.annexList1[index].fileName
      let filePath = this.mainForm.annexList1[index].filePath
      this.mainForm.annexList1.splice(index, 1)
      this.mainForm.introduceName = ''
      this.mainForm.introducePath = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef1.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef1.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef1.deleteIndex(j)
            fileList = this.$refs.coverPictureRef1.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 营业执照文件上传显示回调
     */
    picFileShowFileList2(showList) {
      showList.forEach(item => {
        let flag = this.mainForm.annexList2.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.mainForm.annexList2.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid2()
      }
    },
    // 获取营业执照文件的UID
    generateFilesUid2() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef2.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 营业执照文件上传成功回调
     */
    picFileUploadSuccess2(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList2) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.mainForm.annexList2) {
          for (let j in fileList) {
            if (this.mainForm.annexList2[i].fileName === fileList[j].fileName) {
              this.mainForm.annexList2[i].filePath = fileList[j].filePath
              this.mainForm.licenseName = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList2) ? this.mainForm.annexList2[0].fileName : null
              this.mainForm.licensePath = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList2) ? this.mainForm.annexList2[0].filePath : null
            }
          }
        }
      }
    },
    /**
     * 删除营业执照文件
     */
    deletionPicFile2(index) {
      let fileName = this.mainForm.annexList2[index].fileName
      let filePath = this.mainForm.annexList2[index].filePath
      this.mainForm.annexList2.splice(index, 1)
      this.mainForm.licenseName = ''
      this.mainForm.licensePath = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef2.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef2.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef2.deleteIndex(j)
            fileList = this.$refs.coverPictureRef2.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 员工证明文件上传显示回调
     */
    picFileShowFileList3(showList) {
      showList.forEach(item => {
        let flag = this.mainForm.annexList3.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.mainForm.annexList3.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid3()
      }
    },
    // 获取员工证明文件的UID
    generateFilesUid3() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef3.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 员工证明文件上传成功回调
     */
    picFileUploadSuccess3(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList3) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.mainForm.annexList3) {
          for (let j in fileList) {
            if (this.mainForm.annexList3[i].fileName === fileList[j].fileName) {
              this.mainForm.annexList3[i].filePath = fileList[j].filePath
              this.mainForm.employerName = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList3) ? this.mainForm.annexList3[0].fileName : null
              this.mainForm.employerPath = this.$zwxBase.verifyIsNotBlank(this.mainForm.annexList3) ? this.mainForm.annexList3[0].filePath : null
            }
          }
        }
      }
    },
    /**
     * 删除员工证明文件
     */
    deletionPicFile3(index) {
      let fileName = this.mainForm.annexList3[index].fileName
      let filePath = this.mainForm.annexList3[index].filePath
      this.mainForm.annexList3.splice(index, 1)
      this.mainForm.employerName = ''
      this.mainForm.employerPath = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef3.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef3.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef3.deleteIndex(j)
            fileList = this.$refs.coverPictureRef3.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style scoped lang="less">
.tech-service-basic-edit {
  padding-top: 20px;
  .select-component {
    /deep/ .zwx-input {
      width: 240px !important;
      cursor: pointer;
    }

    /deep/ .el-input__suffix {
      right: 6px !important;
    }

    /deep/ .el-input__inner {
      width: 240px;
    }
    /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
      margin-top: 0;
      line-height: 32px !important;
    }
  }
}
</style>
